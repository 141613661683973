.solutions {
  padding: 60px var(--x-padding);
}

.aboutReadMe {
  padding-top: 15px;
}

.productsTitle {
  font-size: 40px;
  line-height: 50px;
}
@media only screen and (max-width: 576px) {
  .productsTitle {
    font-size: 30px;
    line-height: 37px;
  }
}

.primaryColorText {
  color: var(--primary-color);
}
.secondaryColorText {
  color: var(--secondary-color);
}

.readmeImageContainer {
  margin: 30px 0;
}

.sceneTextReader {
  padding-top: 150px;
}

.idCardReader {
  padding-top: 30px;
  padding-bottom: 30px;
}
.idCardReaderImages {
  display: flex;
}
.idCardReaderImages img {
  width: 60%;
}

.assetTrackingNumberReader {
  padding-top: 30px;
}

.coloredText {
  color: var(--primary-color);
}

.solutionsTitle {
  font-size: 30px;
  line-height: 37px;
}
@media only screen and (max-width: 576px) {
  .solutionsTitle {
    font-size: 25px;
    line-height: 28px;
    font-weight: 500;
  }
}

.customizableTextReader {
  padding: 15px 0;
}

.digitalTransformationService {
  padding-bottom: 60px;
}

.assetTrackingNumberReaderImages {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 1200px) {
  .assetTrackingNumberReaderImages {
    flex-direction: column;
    align-items: flex-end;
    justify-self: flex-start;
  }
}
@media only screen and (max-width: 768px) {
  .assetTrackingNumberReaderImages {
    flex-direction: column;
    align-items: center;
    justify-self: flex-start;
  }
}

.documentReader {
  padding-top: 150px;
}

.ourServices {
  padding-top: 150px;
}

.bankStatementReader {
  padding-top: 15px;
}
