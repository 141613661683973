.profile {
  color: var(--dark-color);
  max-width: 650px;
}

.titleRow {
  display: flex;
}
@media only screen and (max-width: 1200px) {
  .titleRow {
    flex-direction: column;
  }
}

.profilePicture {
  width: 155px;
  height: 155px;
}

.titleText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 35px;
}

.name {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
}

.nameTrailing {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #717171;
}

.position {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: var(--primary-color);
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 27px;
  padding: 25px 0 0 32px;
  color: #717171;
}

@media only screen and (max-width: 992px) {
  .titleRow {
    flex-direction: column;
  }

  .titleText {
    align-items: flex-start;
    margin-left: 0;
  }

  .content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .titleRow {
    align-items: center;
  }
}

@media only screen and (max-width: 576px) {
  .name {
    font-size: 30px;
    line-height: 37px;
  }

  .position {
    font-size: 28px;
    line-height: 35px;
  }

  .nameTrailing {
    font-size: 20px;
    line-height: 24px;
  }

  .content {
    font-size: 18px;
    line-height: 23px;
  }
}
