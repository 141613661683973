.wrapper {
  position: relative;
}

.child {
  position: relative;
}
.text {
  position: relative;
  z-index: 1;
}

.highlight {
  position: absolute;
  z-index: 0;
}

.start {
  left: -12px;
}
.end {
  right: -12px;
}

.primary {
  background-color: var(--primary-color);
}
.secondary {
  background-color: var(--secondary-color);
}
