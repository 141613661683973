.doubleGridRow {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.leftGroup {
  flex: 1;
  margin-right: 100px;
  margin-bottom: 0;
}
.rightGroup {
  flex: 1;
}
@media only screen and (max-width: 768px) {
  .doubleGridRow {
    flex-direction: column;
  }

  .leftGroup {
    flex: none;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .rightGroup {
    flex: none;
  }

  .reverse {
    flex-direction: column-reverse;
  }
  .reverse .leftGroup {
    margin-right: 0;
    margin-bottom: 0;
  }
  .reverse .rightGroup {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
