.article.cappedWidth {
  max-width: 650px;
}
.article.dark {
  color: var(--dark-color);
}
.article.light {
  color: var(--light-color);
}

.title {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
}

.divider {
  width: 100%;
  margin: 15px 0 35px 0;
}
.article.dark .divider {
  border-bottom: 1px solid var(--separator-color);
}
.article.light .divider {
  border-bottom: 1px solid var(--light-color);
}

.spacing {
  width: 100%;
}

.body {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 27px;
}

@media only screen and (max-width: 576px) {
  .title {
    font-size: 36px;
    line-height: 45px;
  }
  .body {
    font-size: 18px;
    line-height: 23px;
  }
  .divider {
    width: 100%;
    margin: 10px 0 25px 0;
  }
}
