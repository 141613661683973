.aboutEikonnex {
  padding: 60px var(--x-padding) 20px var(--x-padding);
}

.visionMission {
  padding: 80px var(--x-padding) 0 var(--x-padding);
  position: relative;
}

.doubleCircleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.doubleCircleContainer img {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .doubleCircleContainer {
    display: none;
  }
}

.halfEllipse {
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  right: 0;
  transform-origin: top right;
}
@media only screen and (max-width: 768px) {
  .halfEllipse {
    opacity: 0.2;
  }
}

.ourTeam {
  padding: 80px var(--x-padding) 0 var(--x-padding);
  position: relative;
}

.ourTeamPicture {
  width: 100%;
}

.coFounders {
  padding: 120px var(--x-padding) 60px var(--x-padding);
  position: relative;
}

.coFoundersSpacing {
  height: 75px;
}

.coloredText {
  color: var(--primary-color);
}
