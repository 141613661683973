.footer {
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: 20px var(--x-padding);

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 992px) {
  .footer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .contact {
    margin-bottom: 50px;
  }
}

.contactTitle {
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  font-family: var(--content-font);
}

.contactInfo svg {
  margin-right: 10px;
}
.contactInfo .divider {
  height: 0.5px;
  width: 100%;
  background-color: var(--light-color);
  opacity: 0.75;
  margin: 5px 0;
}

.contactInfoItem {
  display: flex;
  align-items: center;

  font-size: 18px;
  font-style: italic;
  font-weight: 300;
}
