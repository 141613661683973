.navbar,
.navbarMobile {
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: 15px var(--x-padding);

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 2;
}

.leftGroup {
  display: flex;
  align-items: center;
}
.rightGroup {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 50px;
}
.logo img {
  width: 150px;
}

.leftGroup .navItem,
.leftGroup .navItemDropdown {
  margin-right: 30px;
}

.navItem:hover {
  opacity: 0.75;
}
.navItem,
.navItem a {
  color: var(--light-color);
  text-decoration: none;
  cursor: pointer;
}

.navItemDropdown {
  position: relative;
}
.navItemDropdown,
.navItemDropdown a {
  color: var(--light-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}
.navItemDropdown img {
  width: 25px;
  height: 25px;
}

.navItemDropdown > .navItemDropdownList {
  display: none;
}
.navItemDropdown:hover > .navItemDropdownList {
  display: block;
}
.navItemDropdownList:hover {
  display: block;
}

.navItemDropdownItem {
  white-space: nowrap;
  padding: 5px 0;
  border-bottom: 1px solid #b8b8b8;
}

.navItemDropdownSubItem {
  white-space: nowrap;
  padding: 5px 0;
  padding-left: 20px;
}
.navItemDropdownSubItem:hover {
  background: #414441;
}

.navItemDropdownList {
  background: var(--dark-color);
  border: 1px solid var(--light-color);
  padding: 10px;

  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  z-index: 2;
}

.hamburgerButton {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mobileMenu {
  background: #474747;
  padding: 5px var(--x-padding);

  position: absolute;
  width: 100vw;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  z-index: 2;
}

.mobileMenuItem {
  padding: 10px 0;
  border-bottom: 1px solid #b8b8b8;
}
.mobileMenuItem a {
  color: var(--light-color);
  text-decoration: none;
}
.mobileMenuItem:last-child {
  border-bottom: none;
}

.mobileMenuSubHeader {
  margin-left: 20px;
  color: #b8b8b8;
  font-style: italic;
}
.mobileMenuSubItem {
  margin-left: 40px;
  padding: 5px 0;
}
