@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
/* font-family: 'Merriweather Sans', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* font-family: 'Fira Sans', sans-serif; */

:root {
  --header-font: 'Merriweather Sans', sans-serif;
  --content-font: 'Fira Sans', sans-serif;
  --primary-color: #5080ed;
  --secondary-color: #fe9200;
  --dark-color: #2f322f;
  --light-color: #ffffff;
  --separator-color: #c4c4c4;
  --x-padding: 150px;

  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
}
@media only screen and (max-width: 992px) {
  :root {
    --x-padding: 30px;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--content-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
