.app {
  position: relative;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.fillBody {
  flex: 1;
}
