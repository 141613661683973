.landing {
  padding: 80px var(--x-padding);
  background: linear-gradient(180deg, #2f322f 50%, #5080ed 100%);
  position: relative;
  display: flex;
  color: var(--light-color);
  min-height: 50vh;
}

.landing .leftGroup {
  flex: 1;
}
.landing .rightGroup {
  flex: 1;
}

.landing .title {
  font-size: 72px;
  line-height: 95px;
  padding-left: 25px;
}

.landing .subtitle {
  font-size: 36px;
  line-height: 45px;
  padding-left: 25px;
  font-weight: 300;
}

.curve {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.graph {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 30vw;
  max-width: 600px;
}

.readMeHighlights {
  padding: 80px var(--x-padding) 40px var(--x-padding);
}

.tryDemoContainer a {
  text-decoration: none;
  display: inline-block;
}

.highlightsCardContainer {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 576px) {
  .highlightsCardContainer {
    align-items: center;
  }
}

.demoButton {
  background: #fff2e1;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.25s;
}
.demoButton:hover {
  background-color: #ffeace;
  border: 2px solid #ffeace;
}
.demoButtonText {
  font-size: 18px;
  text-decoration: none;
  color: #fe9200;
}
.demoButton svg {
  margin-left: 10px;
}

.partners {
  padding: 80px var(--x-padding);
}

.partnersLogo {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}
.partnersLogo img {
  margin: 0 45px 30px 0;
}

@media only screen and (max-width: 1200px) {
  .partnersLogo {
    justify-content: center;
  }
}
@media only screen and (max-width: 576px) {
  .partnersLogo {
    flex-direction: column;
  }
  .partnersLogo > * {
    margin: 0 0 40px 0;
    max-width: 100%;
  }
}

.coloredText {
  color: var(--primary-color);
}
