.highlightCardPadding {
  display: inline-block;
  flex: 25%;
  padding: 0 25px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1350px) {
  .highlightCardPadding {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 1150px) {
  .highlightCardPadding {
    padding: 0 10px;
    flex: 50%;
  }
}
@media only screen and (max-width: 576px) {
  .highlightCardPadding {
    padding: 0 10px;
    flex: 100%;
  }
}

.highlightCard {
  border: 1px solid var(--separator-color);
  height: 400px;
  border-radius: 25px;
  box-shadow: -2px 5px 10px #fff5e7;
  max-width: 280px;
}

.highlightCardHeader {
  height: 20%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #ffeace;
  padding: 15px;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

.lowerPart {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .highlightCard {
    height: 325px;
  }
  .highlightCardHeader {
    height: 25%;
  }
  .lowerPart {
    height: 75%;
  }
}

.body {
  font-size: 18px;
  line-height: 24px;
  padding-right: 15px;
  color: #5c5c5c;
}
.body li {
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
