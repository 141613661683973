.title {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 36px;

  color: var(--dark-color);

  margin-bottom: 30px;
}

@media only screen and (max-width: 576px) {
  .title {
    font-size: 36px;
    line-height: 27px;

    margin-bottom: 20px;
  }
}

.title > * {
  display: flex;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--separator-color);
}
