@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* font-family: 'Merriweather Sans', sans-serif; */
/* font-family: 'Fira Sans', sans-serif; */

:root {
  --header-font: 'Merriweather Sans', sans-serif;
  --content-font: 'Fira Sans', sans-serif;
  --primary-color: #5080ed;
  --secondary-color: #fe9200;
  --dark-color: #2f322f;
  --light-color: #ffffff;
  --separator-color: #c4c4c4;
  --x-padding: 150px;

  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
}
@media only screen and (max-width: 992px) {
  :root {
    --x-padding: 30px;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  font-family: var(--content-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.styles_wrapper__3R8gP {
  position: relative;
}

.styles_child__2_b13 {
  position: relative;
}
.styles_text__3sJyQ {
  position: relative;
  z-index: 1;
}

.styles_highlight__inbDo {
  position: absolute;
  z-index: 0;
}

.styles_start__DuSDY {
  left: -12px;
}
.styles_end__29bqf {
  right: -12px;
}

.styles_primary__2NGab {
  background-color: var(--primary-color);
}
.styles_secondary__3jVl_ {
  background-color: var(--secondary-color);
}

.styles_article__ebx7F.styles_cappedWidth__3cRGc {
  max-width: 650px;
}
.styles_article__ebx7F.styles_dark__2DAxS {
  color: var(--dark-color);
}
.styles_article__ebx7F.styles_light__3teNd {
  color: var(--light-color);
}

.styles_title__nqaP4 {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
}

.styles_divider__3L2Z2 {
  width: 100%;
  margin: 15px 0 35px 0;
}
.styles_article__ebx7F.styles_dark__2DAxS .styles_divider__3L2Z2 {
  border-bottom: 1px solid var(--separator-color);
}
.styles_article__ebx7F.styles_light__3teNd .styles_divider__3L2Z2 {
  border-bottom: 1px solid var(--light-color);
}

.styles_spacing__3a6rg {
  width: 100%;
}

.styles_body__1COlY {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 27px;
}

@media only screen and (max-width: 576px) {
  .styles_title__nqaP4 {
    font-size: 36px;
    line-height: 45px;
  }
  .styles_body__1COlY {
    font-size: 18px;
    line-height: 23px;
  }
  .styles_divider__3L2Z2 {
    width: 100%;
    margin: 10px 0 25px 0;
  }
}

.styles_highlightCardPadding__2-4av {
  display: inline-block;
  flex: 25% 1;
  padding: 0 25px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1350px) {
  .styles_highlightCardPadding__2-4av {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 1150px) {
  .styles_highlightCardPadding__2-4av {
    padding: 0 10px;
    flex: 50% 1;
  }
}
@media only screen and (max-width: 576px) {
  .styles_highlightCardPadding__2-4av {
    padding: 0 10px;
    flex: 100% 1;
  }
}

.styles_highlightCard__363UO {
  border: 1px solid var(--separator-color);
  height: 400px;
  border-radius: 25px;
  box-shadow: -2px 5px 10px #fff5e7;
  max-width: 280px;
}

.styles_highlightCardHeader__1Jsa8 {
  height: 20%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #ffeace;
  padding: 15px;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

.styles_lowerPart__czo0J {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .styles_highlightCard__363UO {
    height: 325px;
  }
  .styles_highlightCardHeader__1Jsa8 {
    height: 25%;
  }
  .styles_lowerPart__czo0J {
    height: 75%;
  }
}

.styles_body__13XOl {
  font-size: 18px;
  line-height: 24px;
  padding-right: 15px;
  color: #5c5c5c;
}
.styles_body__13XOl li {
  margin-bottom: 10px;
}

.styles_footer__2_dZu {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

.styles_landing__2lGUj {
  padding: 80px var(--x-padding);
  background: linear-gradient(180deg, #2f322f 50%, #5080ed 100%);
  position: relative;
  display: flex;
  color: var(--light-color);
  min-height: 50vh;
}

.styles_landing__2lGUj .styles_leftGroup__11Ayi {
  flex: 1 1;
}
.styles_landing__2lGUj .styles_rightGroup__3xgWl {
  flex: 1 1;
}

.styles_landing__2lGUj .styles_title__aW9f- {
  font-size: 72px;
  line-height: 95px;
  padding-left: 25px;
}

.styles_landing__2lGUj .styles_subtitle__2l3g5 {
  font-size: 36px;
  line-height: 45px;
  padding-left: 25px;
  font-weight: 300;
}

.styles_curve__2SQ6f {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.styles_graph__-5uko {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 30vw;
  max-width: 600px;
}

.styles_readMeHighlights__3ce2U {
  padding: 80px var(--x-padding) 40px var(--x-padding);
}

.styles_tryDemoContainer__309pp a {
  text-decoration: none;
  display: inline-block;
}

.styles_highlightsCardContainer__mZOFB {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 576px) {
  .styles_highlightsCardContainer__mZOFB {
    align-items: center;
  }
}

.styles_demoButton__3Cq05 {
  background: #fff2e1;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.25s;
}
.styles_demoButton__3Cq05:hover {
  background-color: #ffeace;
  border: 2px solid #ffeace;
}
.styles_demoButtonText__XqihA {
  font-size: 18px;
  text-decoration: none;
  color: #fe9200;
}
.styles_demoButton__3Cq05 svg {
  margin-left: 10px;
}

.styles_partners__3-DVs {
  padding: 80px var(--x-padding);
}

.styles_partnersLogo__3y_Kc {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}
.styles_partnersLogo__3y_Kc img {
  margin: 0 45px 30px 0;
}

@media only screen and (max-width: 1200px) {
  .styles_partnersLogo__3y_Kc {
    justify-content: center;
  }
}
@media only screen and (max-width: 576px) {
  .styles_partnersLogo__3y_Kc {
    flex-direction: column;
  }
  .styles_partnersLogo__3y_Kc > * {
    margin: 0 0 40px 0;
    max-width: 100%;
  }
}

.styles_coloredText__2H0jw {
  color: var(--primary-color);
}

.styles_doubleGridRow__3w0Iu {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.styles_leftGroup__3dU_4 {
  flex: 1 1;
  margin-right: 100px;
  margin-bottom: 0;
}
.styles_rightGroup__CVVEa {
  flex: 1 1;
}
@media only screen and (max-width: 768px) {
  .styles_doubleGridRow__3w0Iu {
    flex-direction: column;
  }

  .styles_leftGroup__3dU_4 {
    flex: none;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .styles_rightGroup__CVVEa {
    flex: none;
  }

  .styles_reverse__nHr0c {
    flex-direction: column-reverse;
  }
  .styles_reverse__nHr0c .styles_leftGroup__3dU_4 {
    margin-right: 0;
    margin-bottom: 0;
  }
  .styles_reverse__nHr0c .styles_rightGroup__CVVEa {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.styles_profile__2ktbm {
  color: var(--dark-color);
  max-width: 650px;
}

.styles_titleRow__2YYil {
  display: flex;
}
@media only screen and (max-width: 1200px) {
  .styles_titleRow__2YYil {
    flex-direction: column;
  }
}

.styles_profilePicture__3hnbZ {
  width: 155px;
  height: 155px;
}

.styles_titleText__19sZ5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 35px;
}

.styles_name__1iXfC {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
}

.styles_nameTrailing__3dHyE {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #717171;
}

.styles_position__2WFsM {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: var(--primary-color);
}

.styles_content__25-YU {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 27px;
  padding: 25px 0 0 32px;
  color: #717171;
}

@media only screen and (max-width: 992px) {
  .styles_titleRow__2YYil {
    flex-direction: column;
  }

  .styles_titleText__19sZ5 {
    align-items: flex-start;
    margin-left: 0;
  }

  .styles_content__25-YU {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .styles_titleRow__2YYil {
    align-items: center;
  }
}

@media only screen and (max-width: 576px) {
  .styles_name__1iXfC {
    font-size: 30px;
    line-height: 37px;
  }

  .styles_position__2WFsM {
    font-size: 28px;
    line-height: 35px;
  }

  .styles_nameTrailing__3dHyE {
    font-size: 20px;
    line-height: 24px;
  }

  .styles_content__25-YU {
    font-size: 18px;
    line-height: 23px;
  }
}

.styles_title__3RPqu {
  font-family: var(--header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 36px;

  color: var(--dark-color);

  margin-bottom: 30px;
}

@media only screen and (max-width: 576px) {
  .styles_title__3RPqu {
    font-size: 36px;
    line-height: 27px;

    margin-bottom: 20px;
  }
}

.styles_title__3RPqu > * {
  display: flex;
}

.styles_divider__2YNKY {
  width: 100%;
  height: 1px;
  background-color: var(--separator-color);
}

.styles_aboutEikonnex__3MITt {
  padding: 60px var(--x-padding) 20px var(--x-padding);
}

.styles_visionMission__3vJ9V {
  padding: 80px var(--x-padding) 0 var(--x-padding);
  position: relative;
}

.styles_doubleCircleContainer__36xEu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_doubleCircleContainer__36xEu img {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .styles_doubleCircleContainer__36xEu {
    display: none;
  }
}

.styles_halfEllipse__1gmpe {
  position: absolute;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  bottom: 0;
  right: 0;
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
@media only screen and (max-width: 768px) {
  .styles_halfEllipse__1gmpe {
    opacity: 0.2;
  }
}

.styles_ourTeam__35T7- {
  padding: 80px var(--x-padding) 0 var(--x-padding);
  position: relative;
}

.styles_ourTeamPicture__2x7jT {
  width: 100%;
}

.styles_coFounders__LXYWm {
  padding: 120px var(--x-padding) 60px var(--x-padding);
  position: relative;
}

.styles_coFoundersSpacing__1fQNU {
  height: 75px;
}

.styles_coloredText__3zB7Y {
  color: var(--primary-color);
}

.styles_solutions__1YdNH {
  padding: 60px var(--x-padding);
}

.styles_aboutReadMe__11hYd {
  padding-top: 15px;
}

.styles_productsTitle__EbS1J {
  font-size: 40px;
  line-height: 50px;
}
@media only screen and (max-width: 576px) {
  .styles_productsTitle__EbS1J {
    font-size: 30px;
    line-height: 37px;
  }
}

.styles_primaryColorText__FgBOR {
  color: var(--primary-color);
}
.styles_secondaryColorText__c6gNh {
  color: var(--secondary-color);
}

.styles_readmeImageContainer__1Q0Sr {
  margin: 30px 0;
}

.styles_sceneTextReader__2DPTH {
  padding-top: 150px;
}

.styles_idCardReader__1A1gt {
  padding-top: 30px;
  padding-bottom: 30px;
}
.styles_idCardReaderImages__3aTkw {
  display: flex;
}
.styles_idCardReaderImages__3aTkw img {
  width: 60%;
}

.styles_assetTrackingNumberReader__lzVnZ {
  padding-top: 30px;
}

.styles_coloredText__3p0ll {
  color: var(--primary-color);
}

.styles_solutionsTitle__f9_Jj {
  font-size: 30px;
  line-height: 37px;
}
@media only screen and (max-width: 576px) {
  .styles_solutionsTitle__f9_Jj {
    font-size: 25px;
    line-height: 28px;
    font-weight: 500;
  }
}

.styles_customizableTextReader__4XmOp {
  padding: 15px 0;
}

.styles_digitalTransformationService__13qgc {
  padding-bottom: 60px;
}

.styles_assetTrackingNumberReaderImages__oTrza {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 1200px) {
  .styles_assetTrackingNumberReaderImages__oTrza {
    flex-direction: column;
    align-items: flex-end;
    justify-self: flex-start;
  }
}
@media only screen and (max-width: 768px) {
  .styles_assetTrackingNumberReaderImages__oTrza {
    flex-direction: column;
    align-items: center;
    justify-self: flex-start;
  }
}

.styles_documentReader__1OpvD {
  padding-top: 150px;
}

.styles_ourServices__174zE {
  padding-top: 150px;
}

.styles_bankStatementReader__3fYIp {
  padding-top: 15px;
}

.styles_navbar__1OcZZ,
.styles_navbarMobile__2c7Gq {
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: 15px var(--x-padding);

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: -webkit-sticky;

  position: sticky;
  top: 0;
  z-index: 2;
}

.styles_leftGroup__3NuK5 {
  display: flex;
  align-items: center;
}
.styles_rightGroup__HBB4_ {
  display: flex;
  align-items: center;
}

.styles_logo__3VzeI {
  margin-right: 50px;
}
.styles_logo__3VzeI img {
  width: 150px;
}

.styles_leftGroup__3NuK5 .styles_navItem__1bc-h,
.styles_leftGroup__3NuK5 .styles_navItemDropdown__17nJr {
  margin-right: 30px;
}

.styles_navItem__1bc-h:hover {
  opacity: 0.75;
}
.styles_navItem__1bc-h,
.styles_navItem__1bc-h a {
  color: var(--light-color);
  text-decoration: none;
  cursor: pointer;
}

.styles_navItemDropdown__17nJr {
  position: relative;
}
.styles_navItemDropdown__17nJr,
.styles_navItemDropdown__17nJr a {
  color: var(--light-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}
.styles_navItemDropdown__17nJr img {
  width: 25px;
  height: 25px;
}

.styles_navItemDropdown__17nJr > .styles_navItemDropdownList__374Ou {
  display: none;
}
.styles_navItemDropdown__17nJr:hover > .styles_navItemDropdownList__374Ou {
  display: block;
}
.styles_navItemDropdownList__374Ou:hover {
  display: block;
}

.styles_navItemDropdownItem__3Hv65 {
  white-space: nowrap;
  padding: 5px 0;
  border-bottom: 1px solid #b8b8b8;
}

.styles_navItemDropdownSubItem__2ws9q {
  white-space: nowrap;
  padding: 5px 0;
  padding-left: 20px;
}
.styles_navItemDropdownSubItem__2ws9q:hover {
  background: #414441;
}

.styles_navItemDropdownList__374Ou {
  background: var(--dark-color);
  border: 1px solid var(--light-color);
  padding: 10px;

  position: absolute;
  bottom: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  z-index: 2;
}

.styles_hamburgerButton__1q-om {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.styles_mobileMenu__10Z1l {
  background: #474747;
  padding: 5px var(--x-padding);

  position: absolute;
  width: 100vw;
  left: 0;
  bottom: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  z-index: 2;
}

.styles_mobileMenuItem__25JwE {
  padding: 10px 0;
  border-bottom: 1px solid #b8b8b8;
}
.styles_mobileMenuItem__25JwE a {
  color: var(--light-color);
  text-decoration: none;
}
.styles_mobileMenuItem__25JwE:last-child {
  border-bottom: none;
}

.styles_mobileMenuSubHeader__jeLNc {
  margin-left: 20px;
  color: #b8b8b8;
  font-style: italic;
}
.styles_mobileMenuSubItem__eFXhK {
  margin-left: 40px;
  padding: 5px 0;
}

.styles_footer__On2LT {
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: 20px var(--x-padding);

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 992px) {
  .styles_footer__On2LT {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .styles_contact__2xbPR {
    margin-bottom: 50px;
  }
}

.styles_contactTitle__SLnz4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  font-family: var(--content-font);
}

.styles_contactInfo__1XfTQ svg {
  margin-right: 10px;
}
.styles_contactInfo__1XfTQ .styles_divider__wYULH {
  height: 0.5px;
  width: 100%;
  background-color: var(--light-color);
  opacity: 0.75;
  margin: 5px 0;
}

.styles_contactInfoItem__T5rwW {
  display: flex;
  align-items: center;

  font-size: 18px;
  font-style: italic;
  font-weight: 300;
}

.app_app__1Nn1E {
  position: relative;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.app_fillBody__21W64 {
  flex: 1 1;
}

